<template>
  <div
    class="horizontal-layout"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height:inherit"
  >

    <!-- NAVBAR -->
    <b-navbar
      :style="{
        backgroundColor: navbarType === 'static' && scrolledTo && skin === 'light' ? 'white' : null,
        boxShadow: navbarType === 'static' && scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null,
      }"
      :toggleable="false"
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
      :class="{'fixed-top': $store.getters['app/currentBreakPoint'] !== 'xl'}"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
      >
        <app-navbar-horizontal-layout-brand />
        <app-navbar-horizontal-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ NAVBAR -->

    <div class="horizontal-menu-wrapper">
      <div
        v-if="!isNavMenuHidden"
        class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border d-none d-xl-block"
        :class="[navbarMenuTypeClass]"
      >
        <horizontal-nav-menu />
      </div>

      <!-- Vertical Nav Menu -->
      <vertical-nav-menu
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        class="d-block d-xl-none"
      >
        <template #header="slotProps">
          <slot
            name="vertical-menu-header"
            v-bind="slotProps"
          />
        </template>
      </vertical-nav-menu>
      <header class="header">
        <!-- <h1>Happy Diwali 🎉</h1> -->
        <div v-for="sparkle in sparkles" :key="sparkle.id" :style="sparkle.style" class="sparkle"></div>
      </header>
    <!-- /Vertical Nav Menu -->
    </div>

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>

    <slot name="customizer" />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

import { onUnmounted } from '@vue/composition-api'

// Content Renderer
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import useLayoutHorizontal from './useLayoutHorizontal'
import HorizontalNavMenu from './components/horizontal-nav-menu/HorizontalNavMenu.vue'

// Vertical Menu
/* eslint-disable import/order */
import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import mixinLayoutHorizontal from './mixinLayoutHorizontal'
/* eslint-enable import/order */

export default {
  data() {
    return {
      sparkles: []
    };
  },
  components: {
    AppBreadcrumb,
    AppNavbarHorizontalLayout,
    AppNavbarHorizontalLayoutBrand,
    AppFooter,
    HorizontalNavMenu,

    BNavbar,

    // Content Renderer
    LayoutContentRendererDefault,
    LayoutContentRendererLeft,
    LayoutContentRendererLeftDetached,

    // Vertical Menu
    VerticalNavMenu,
  },
  mixins: [mixinLayoutHorizontal],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
    } = useAppConfig()

    // Vertical Menu
    const {
      isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const {
      navbarMenuTypeClass,
      layoutClasses,
      footerTypeClass,
    } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    return {
      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
    }
  },
  mounted() {
    this.generateSparkles();
  },
  methods: {
    generateSparkles() {
      // Generate sparkles every 200 ms
      setInterval(() => {
        const sparkleCount = 10;
        const header = this.$el.querySelector('.header');
        const headerWidth = header.offsetWidth;
        const headerHeight = header.offsetHeight;

        for (let i = 0; i < sparkleCount; i++) {
          const sparkle = {
            id: Date.now() + i,
            style: {
              top: `${Math.random() * headerHeight}px`,
              left: `${Math.random() * headerWidth}px`
            }
          };
          this.sparkles.push(sparkle);

          // Remove sparkle after animation ends
          setTimeout(() => {
            this.sparkles.shift();
          }, 500);
        }
      }, 200);
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
.sparkle {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: #f8d802;
    border-radius: 50%;
    animation: sparkle-animation 0.5s linear infinite;
}

@keyframes sparkle-animation {
    0% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
    50% {
        opacity: 0.8;
        transform: scale(1.5) translateY(-10px);
    }
    100% {
        opacity: 0;
        transform: scale(0.5) translateY(-20px);
    }
}
.header {
  position: relative;
  padding: 20px;
  text-align: center;
  background-color: #282c34;
  color: white;
  font-size: 24px;
}

</style>
